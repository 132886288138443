
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import DropdownEvaluation from "@/components/dropdown/DropdownEvaluation.vue";

export default defineComponent({
  name: "manage-diets",
  components: {
    DropdownEvaluation,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = [
      {
        week: "28.01.2022 - 03.02.2022",
        targetHours: "40",
        actualHours: "0",
        bonus100: "-40",
        bonus25: "0",
      },
      {
        week: "04.02.2022 - 10.02.2022",
        targetHours: "40",
        actualHours: "0",
        bonus100: "-40",
        bonus25: "0",
      },
      {
        week: "11.02.2022 - 17.02.2022",
        targetHours: "40",
        actualHours: "0",
        bonus100: "-40",
        bonus25: "0",
      },
    ];

    onMounted(() => {
      setCurrentPageTitle("dataManagement.overtimes");
    });

    const searchItems = () => {
      //TODO
    };

    return {
      list,
    };

  },
});
