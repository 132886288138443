
import {defineComponent, ref} from "vue";

interface Filter {
  status: string;
}

export default defineComponent({
  name: "evaluation-dropdown",
  components: {},
  setup() {
    const data = ref<Filter>({
      status: "1",
    });

    return {
      data,
    };
  },
});
